import { Close } from "@mui/icons-material";
import Form from "../datatable/Form";
import SubmitButton from "../datatable/SubmitButton";
import "./editaddwidget.scss";
import { useEffect } from "react";
import { motion } from "framer-motion";

const EditAddWidget = (props) => {
  const {
    handleSubmit,
    date,
    onChangeDate,
    handleInput,
    categories,
    dataInput,
    setDataInput,
    handleClose,
    isSubmitting,
    isEditing,
    type,
    setConfirmDelete,
    editingId,
    sheetId,
  } = props;

  // lock scrolling
  useEffect(() => {
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{
        opacity: 1,
        transition: { duration: 0.4, ease: [0.36, 0.66, 0.04, 1] },
      }}
      exit={{
        opacity: 0,
        transition: { duration: 0.3, ease: [0.36, 0.66, 0.04, 1] },
      }}
      className="editaddwidget"
    >
      <motion.div
        initial={{ scale: 0.8, opacity: 0 }}
        animate={{
          scale: 1,
          opacity: 1,
          transition: {
            delay: 0.1,
            duration: 0.4,
            type: "spring",
            ease: [0.36, 0.66, 0.04, 1],
          },
        }}
        exit={{
          scale: 0.8,
          opacity: 0,
          transition: { duration: 0.3, ease: [0.36, 0.66, 0.04, 1] },
        }}
        className="formContainer"
      >
        <div className="closeContainer" onClick={handleClose}>
          <Close className="closeIcon" />
        </div>
        <h1>{isEditing ? "Edit" : "Add"}</h1>
        <Form
          handleSubmit={handleSubmit}
          date={date}
          onChangeDate={onChangeDate}
          handleInput={handleInput}
          setDataInput={setDataInput}
          categories={categories}
          dataInput={dataInput}
          type={type}
          isModal={true}
          sheetId={sheetId}
        />
        <div className="buttonsRow">
          {isEditing && (
            <div className="buttonWrapper">
              <button
                className="btn-cancel"
                onClick={() =>
                  setConfirmDelete({ showMessage: true, id: editingId })
                }
              >
                Delete
              </button>
            </div>
          )}
          <div className="buttonWrapper submit">
            <SubmitButton
              isSubmitting={isSubmitting}
              isEditing={isEditing}
              type={type}
            />
          </div>
        </div>
      </motion.div>
    </motion.div>
  );
};

export default EditAddWidget;
