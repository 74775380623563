import "./accountslinechart.scss";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from "chart.js";
import { useState } from "react";
import { useEffect } from "react";
import { Line } from "react-chartjs-2";
import { useSelector } from "react-redux";
import { motion } from "framer-motion";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

const AccountsLineChart = (props) => {
  const { chartValues, activeAccount } = props;
  const currency = useSelector((state) => state.userSettings.currency);
  const isDarkMode = useSelector((state) => state.userSettings.isDarkMode);
  const [amountToday, setAmountToday] = useState(0);
  const [activePeriod, setActivePeriod] = useState("year");
  const [valuesArray, setValuesArray] = useState([]);

  const currentDate = new Date();
  let startDate = new Date(currentDate);

  switch (activePeriod) {
    case "5y":
      startDate = new Date(startDate.setMonth(startDate.getMonth() - 60));
      break;
    case "year":
      startDate = new Date(startDate.setMonth(startDate.getMonth() - 12));
      break;
    case "quarter":
      startDate = new Date(startDate.setMonth(startDate.getMonth() - 3));
      break;
    case "month":
      startDate = new Date(startDate.setMonth(startDate.getMonth() - 1));
      break;
    case "week":
      startDate = new Date(startDate.setDate(startDate.getDate() - 7));
      break;
    default:
      startDate = new Date(
        currentDate.getFullYear() - 1,
        currentDate.getMonth(),
        currentDate.getDate()
      );
      break;
  }

  let dateArray = [];
  let movingDate = new Date(startDate);
  while (movingDate <= currentDate) {
    dateArray.push(
      movingDate.toLocaleDateString(undefined, { timeZone: "UTC" })
    );
    movingDate.setDate(movingDate.getDate() + 1);
    // console.log({
    //   movingDate: movingDate,
    //   currentDate: currentDate,
    //   logic: movingDate <= currentDate,
    //   movingDatePushed: movingDate.toLocaleDateString(undefined, {
    //     timeZone: "UTC",
    //   }),
    // });
  }
  let dateArrayTrimmed = [];

  if (activePeriod !== "5y") {
    dateArrayTrimmed = dateArray;
  } else {
    dateArray.forEach((dateStr) => {
      if (isEndOfMonth(dateStr)) {
        dateArrayTrimmed.push(dateStr);
      }
    });
  }

  // check in macOs or Apple OS
  // not filterling in ios and macOs
  function isEndOfMonth(dateStr) {
    const date = new Date(dateStr); // Convert string back to Date object
    const currentDate = new Date();
    const month = date.getMonth(); // Get the current month (0-11)
    const nextDay = new Date(date);
    nextDay.setDate(date.getDate() + 1); // Check the next day
    const isCurrentDate =
      date.toLocaleDateString() === currentDate.toLocaleDateString();
    return nextDay.getMonth() !== month || isCurrentDate; // If next day is in a different month, it's end of month
  }

  useEffect(() => {
    let runningBalance = 0;
    // calculate runningBalance before startDate of graph
    if (activeAccount) {
      runningBalance = Object.keys(chartValues?.[activeAccount]).reduce(
        (total, key) => {
          if (startDate > chartValues?.[activeAccount]?.[key].date) {
            return (total += chartValues?.[activeAccount]?.[key].amount);
          } else {
            return total;
          }
        },
        0
      );
    } else {
      runningBalance = Object.keys(chartValues?.consolidated).reduce(
        (total, key) => {
          if (startDate > chartValues?.consolidated[key].date) {
            return (total += chartValues?.consolidated[key].amount);
          } else {
            return total;
          }
        },
        0
      );
    }

    let amountsList = [];
    dateArray.forEach((date) => {
      let dateInflow = 0;
      if (activeAccount) {
        dateInflow = chartValues?.[activeAccount]?.[date]?.amount || 0;
      } else {
        dateInflow = chartValues?.consolidated?.[date]?.amount || 0;
      }
      runningBalance += dateInflow;
      if (activePeriod === "5y") {
        if (isEndOfMonth(date)) {
          amountsList.push(runningBalance);
        }
      } else {
        amountsList.push(runningBalance);
      }
    });

    // array values for the chart
    setValuesArray(amountsList);
    // amount for the total today
    setAmountToday(amountsList[amountsList.length - 1]);
  }, [activeAccount, chartValues, activePeriod]);

  ChartJS.defaults.font.family = "Inter";
  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    hover: {
      mode: "index",
      intersect: false,
    },
    plugins: {
      legend: {
        display: false,
        position: "top",
      },
      title: {
        display: false,
        text: "Chart.js Line Chart",
      },
      tooltip: {
        mode: "index",
        intersect: false,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          color: `${isDarkMode ? "lightgray" : "gray"}`,
        },
        grid: {
          color: `${isDarkMode ? "transparent" : "transparent"}`,
        },
        border: {
          dash: [4, 3],
        },
      },
      x: {
        ticks: {
          color: `${isDarkMode ? "lightgray" : "gray"}`,
        },
        grid: {
          color: `${isDarkMode ? "gray" : "lightgray"}`,
          display: false,
        },
        border: {
          dash: [4, 3],
        },
      },
    },
  };

  const labels = dateArrayTrimmed;
  const values = valuesArray;

  const data = {
    labels,
    datasets: [
      {
        fill: true,
        label: "Balance",
        data: values,
        borderColor: "#138e1a",
        backgroundColor: (context) => {
          if (!context.chart.chartArea) {
            return;
          }
          const {
            ctx,
            data,
            chartArea: { top, bottom },
          } = context.chart;
          const gradientBg = ctx.createLinearGradient(0, top, 0, bottom);
          gradientBg.addColorStop(0, "#138e1a33");
          gradientBg.addColorStop(1, "#138e1a03");
          return gradientBg;
        },
        lineTension: 0.4,
        pointRadius: (ctx) => {
          const pointIndex = ctx.dataIndex;

          if (pointIndex === dateArray.length - 1) {
            return 5; // 5px dot
          }
          return 1; // no dot
        },
      },
    ],
  };

  return (
    <div className="accountslinecharts">
      <div className="summary">
        <div className="todaySummaryContainer">
          <div className="label">Today</div>
          <motion.div layout className="amountToday">
            <span className="currency">{currency.symbol}</span>
            <span className="whole">
              {amountToday?.toLocaleString("en-us").split(".")[0]}
            </span>
            {amountToday?.toLocaleString("en-us").split(".")[1] && (
              <span className="cent">
                {amountToday?.toLocaleString("en-us").split(".")[1]}
              </span>
            )}
          </motion.div>
        </div>
      </div>
      <div className="chartContainer">
        <Line options={chartOptions} data={data} />
      </div>
      <div className="periodOptions">
        <button
          onClick={() => setActivePeriod("week")}
          className={`${activePeriod === "week" ? "active" : ""}`}
        >
          1W
        </button>
        <button
          onClick={() => setActivePeriod("month")}
          className={`${activePeriod === "month" ? "active" : ""}`}
        >
          1M
        </button>
        <button
          onClick={() => setActivePeriod("quarter")}
          className={`${activePeriod === "quarter" ? "active" : ""}`}
        >
          3M
        </button>
        <button
          onClick={() => setActivePeriod("year")}
          className={`${activePeriod === "year" ? "active" : ""}`}
        >
          1Y
        </button>
        <button
          onClick={() => setActivePeriod("5y")}
          className={`${activePeriod === "5y" ? "active" : ""}`}
        >
          5Y
        </button>
      </div>
    </div>
  );
};

export default AccountsLineChart;
